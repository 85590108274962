// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import "~@angular/material/theming";
// Plus imports for other components in your app.
@import url("https://fonts.googleapis.com/css?family=Open+Sans&display=swap");
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();
@import "~@ng-select/ng-select/themes/default.theme.css";
// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$agent-dashboard-primary: mat-palette($mat-indigo);
$agent-dashboard-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
$agent-dashboard-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$agent-dashboard-theme: mat-light-theme($agent-dashboard-primary, $agent-dashboard-accent, $agent-dashboard-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($agent-dashboard-theme);
@import "./variable";

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: $primary-font;
  background-color: $body-background;
}

//chat-box drop down
.attach [_nghost-fpx-c94] {
  height: 3rem;
  display: none;
}


/* Track */
::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
::-webkit-scrollbar-track {
  background: $white;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $icon-color;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $scrollbar-track;
}

// current & past queue tab
.queue-tab__container .mat-tab-header {
  border-bottom: none !important;
}

// spinner
::ng-deep.mat-spinner circle {
  stroke: $primary-green;
}

.pointer {
  cursor: pointer;
}

.q-input .mat-form-field-appearance-outline .mat-form-field-outline {
  color: $primary-green !important;
}

.bg-primary {
  background-color: $primary-green !important;
}

// snack bar

.mat-snack-bar-container {
  background: transparent;
  box-shadow: none;
}

// Color

.primary-text-color {
  color: $primary-green;
}

// search text
.search-text__input {
  display: inline-block;
  text-align: left;
  font-weight: 400;
  font-style: normal;
  font-size: 0.875rem;
  color: $search-text-color;
  height: 2.4rem;
  width: 33.75rem;
  background: rgba(34, 62, 128, 0.1);
  border-radius: 4px;
  background-image: url("assets/search_icon.svg");
  background-position: calc(100% - 98%) center;
  background-repeat: no-repeat;
  padding: 2px;
  border: none;
}

.search-text__input:focus {
  box-shadow: none;
}

.search-text__input:focus {
  outline: 1px solid $search-text-color !important;
  box-shadow: none !important;
  -moz-box-shadow: none !important;
  -webkit-box-shadow: none !important;
}

// Checkbox customiztion

::ng-deep .checkbox .mat-checkbox-inner-container.mat-checkbox-inner-container-no-side-margin {
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
}

::ng-deep .mat-checkbox-checked .mat-checkbox-background {
  background-color: $primary-green !important;
}

::ng-deep .mat-checkbox .mat-checkbox-ripple {
  display: none;
}

//Dashboard Table

.dashboard-table {
  background-color: $white;

  th {
    padding: 15px 20px;
    font-family: $inter-font;
    font-weight: 600;
    font-size: 0.8rem;
    vertical-align: middle;
  }

  td {
    padding: 10px 20px;
    font-size: 0.8rem;
    font-weight: 500;
    vertical-align: middle;
  }

  tbody td {
    border: none;
  }

  & > :not(:first-child) {
    border-top: 1px solid $gray7;
  }

  & tbody tr {
    position: relative;
  }

  & .row-highlight::before{
    content: "";
    width: 0.3rem;
    height: 100%;
    background: #23b082;
    position: absolute;
    top: 0px;
    left: 0px;
    display: none;
    border-radius: 5px;
  }

  & tr:hover {
    outline: 1px solid $light-green;

    & .row-highlight::before {
      display: block;
    }
  }

  .conversation-list:hover {
    background-color: $light-bg;
  }

}

.dashboard-table__pagination{
  font-size: 0.9rem;
}


.heading_text {
  font-weight: 700;
  font-family: $primary-font;
  font-size: 1.5rem;
}

.col-sentiment-image img{
  height: 20px;
}

.no-search-result {
  font-size: 1rem;
  text-align: center;
  opacity: 0.5;
  color: $gray2;
}

.spinner-border{
  height: 1rem;
  width: 1rem;
}

.bot-icon{
  font-size: 22px;
  line-height: 1.6;
}


.bg-light-green {
  background-color: $light-green !important;
}


.project-modal {
  height: auto;
  .mat-dialog-container {
    overflow: hidden;
    padding-bottom: 2.25rem;
  }
  .mat-dialog-actions {
    position: relative;
    min-height: 0;
    span {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    [hidden] {
      display: none;
    }
  }
}

