

$icon-color: grey;
$primary-color: #0080ff;
$secondary-color: #3c506a;
$body-background: #f7f8fa;
$white: #fff;
$black: #000000;
$light-black: #333333;
$light-green: #B5D8CC;
$primary-green: #23b082;
$secondary-green: #5FD182;
$primary-blue: #223e80;
$primary-blue1: #0088ff;
$scrollbar-track: #555;
$light-orange: #FF4D4D;
$gray: #dfe3ee;
$gray1: #BABABA;
$gray2: #999999;
$gray3: #696F79;
$gray4: #ccc;
$gray5: #9a9a9a;
$gray6: #dee2e6;
$gray7: #C4C4C4;
$gray8: #f2f2f2;
$light-bg: #F6F7FA;
$dark-grey: #494444;
$search-text-color: rgba(34, 62, 128, 0.702);
$background-shadow-color: rgba(35, 176, 130, 0.251);
$summary-background-shadow-color: rgba(34, 62, 128, 0.1);;
$text-light-muted: #2f2929;
$dark-grey: #494444;

// Font family

$primary-font : "Poppins";
$inter-font:'Inter', sans-serif;


